/* Reset some default styles */
body, h1, h2, p, ul, li {
    margin: 0;
    padding: 0;
}

/* Basic styling for the body */
body {
    font-family: 'Arial', sans-serif;
}

/* Navbar styles */
.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.brand a {
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 18px;
}

.slogan a {
    text-decoration: none;
    color: #fff;
}

.menu-icon {
    display: none; /* Hide initially on larger screens */
}

.bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 6px 0;
}

.nav-links {
    display: flex;
    gap: 20px;
}

/* Hero image styles */
.hero-image {
    width: 100%;
    max-height: 400px; /* Set a reasonable maximum height for the hero image */
    object-fit: cover;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 768px) {
    .menu-icon {
        display: block; /* Show on smaller screens */
        cursor: pointer;
    }

    .nav-links {
        display: none; /* Hide initially on smaller screens */
        flex-direction: column;
        position: absolute;
        top: 60px;
        left: 0;
        width: 100%;
        background-color: #333;
        padding: 10px;
    }

    .nav-links a {
        color: #fff;
        text-decoration: none;
        margin-bottom: 10px;
    }

    .nav-links.active {
        display: flex;
    }
}

.operators {
    margin-top: 5rem;
    margin-right: 5rem;
    margin-left: 5rem;
    margin-bottom: 5rem;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.operator {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 8px;
    width: 300px; /* Adjust the width based on your design preference */
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }

    & h1 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    & p {
        margin-bottom: 8px;
    }
}


.search-bar {
    margin-top: 20px;
    padding: 10px;
}

.search-bar input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
}