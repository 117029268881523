  
  h1 {
    text-align: center;
    margin-bottom: 20px; /* Add margin at the bottom */
  }
  
  /* Style for input fields */
  label,
  input[type='email'],
  input[type='password'],
  button {
    display: block;
    width: 20%;
    margin-bottom: 10px;
  }
  
  button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  