body {
    font-family: Arial, sans-serif, white;
    margin: 0;
    padding: 0;
   /* background-color: #f2f2f2;*/
}

.navbar {
    background-color: #3498db;
    padding: 10px;
    color: #fff;
    text-align: center;
}

.navbar a {
    color: #fff;
    text-decoration: none;
    margin: 10px;
}
/* Apply styles to the h1 element */
h1 {
    font-family: Arial, sans-serif; /* Change the font if desired */
    color: white; /* Set the font color to white */
   /* background-color: #333; /* Dark background color */
    padding: 20px; /* Adjust padding as needed */
    text-align: center; /* Center the text */
  }
  

.container {
    max-width: 400px;
    margin: 20px auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.card {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 10px;
    width: 300px;
    text-align: center;
    background-color: #f0f0f0;
}

.card img {
    max-width: 60%;
    text-decoration:none;
    max-height: 150px;
    background-color: transparent;
}

.footer {
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    text-align: center;
}
  